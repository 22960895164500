import React, { useEffect } from 'react';

import logo from './imgs/logo.png';
// import ps5 from './imgs/ps5.png';
import ps52 from './imgs/ps5-2.png';
import shoe from './imgs/shoe.png';
import eth from './imgs/eth.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Modal from './Modal';
import { useState } from 'react';

export default function Header() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  {
    return (
      <div className='section'>
        {/* <Modal id='modal' visible={visible} setVisible={setVisible} /> */}
        <img className='shoe' src={shoe} alt='img' />
        <img className='ps5' src={ps52} alt='img' />
        <img className='eth' src={eth} alt='img' />
        <div className='container'>
          <nav className='navbar navbar-expand-xl navbar-light navbar-fixed-top  fixed-top px-5'>
            <a href='/' className='monaco text-sm'>
              <img className='nav-logo' src={logo} alt='img' />
            </a>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbar7'
            >
              <span className='navbar-toggler-icon' />
            </button>
            <div
              className='navbar-collapse collapse justify-content-end'
              id='navbar7'
            >
              <ul className='navbar-nav ml-auto'>
                <li className='nav-item'>
                  <a className='nav-link text-xs text-dark' href='#analytics'>
                    Analytics
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link text-xs text-dark' href='#features'>
                    Features
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link text-xs text-dark' href='#community'>
                    Community
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-xs text-dark'
                    href='#testimonials'
                  >
                    Testimonials
                  </a>
                </li>

                <li className='nav-item'>
                  <a
                    className='nav-link text-xs text-dark'
                    href='https://www.northproxies.com/'
                  >
                    Proxies
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-xs text-dark'
                    href='https://blog.thenorthcop.com/'
                  >
                    Newsletter
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link text-xs text-dark' href='#pricing'>
                    Pricing
                  </a>
                </li>

                <li className='nav-item'>
                  <a
                    className='nav-link text-xs text-dark'
                    href='https://dashboard.thenorthcop.com/dashboard'
                    style={{
                      fontWeight: 'semibold',
                    }}
                  >
                    Login
                  </a>
                </li>
                <li className='nav-item join-hover'>
                  <a
                    className='nav-link text-sm  round text-xs'
                    style={{
                      color: '#df1414',
                    }}
                    onClick={() => {
                      document
                        .querySelector('.navbar-collapse')
                        .classList.remove('show');
                      // open tab to dashboard purchase page
                      window.open(
                        'https://dashboard.thenorthcop.com/?password=joinnow',
                        '_blank'
                      );
                      // window.scrollTo(0, 0);
                      // setVisible(!visible);
                    }}
                  >
                    Join Now
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <div className='header-content d-flex flex-column align-items-center'>
            <p className='text-red text-xs'>Welcome to TheNorthCop</p>
            <h1
              data-aos-duration='1000'
              data-aos='fade-down'
              className='text-xl bold text-dark text-center w-75'
            >
              Canada's most <span className='text-red'>profitable</span>{' '}
              reselling network
            </h1>
            <p
              data-aos='fade-up'
              data-aos-duration='1500'
              className='text-xs opaque text-center w-75 mt-5'
            >
              Whether you’re new or an already established reseller, our
              community helps members get access to all areas of profitable
              items at retail prices to increase your reselling success. With
              TheNorthCop you will never miss a drop or restock.
            </p>
            <button
              className='btn-red text-sm round md:mt-5 space '
              onClick={() => {
                // window.scrollTo(0, 0);
                // setVisible(!visible);
                // open tab to dashboard purchase page
                window.open(
                  'https://dashboard.thenorthcop.com/?password=joinnow',
                  '_blank'
                );
              }}
              style={{
                cursor: 'pointer !important',
                border: 'none',
              }}
            >
              Join Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}
