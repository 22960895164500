import React from 'react';
import logo from './imgs/logo.png';
// import Modal from './Modal';
import { useState } from 'react';

export default function Footer() {
  const [visible, setVisible] = useState(false);

  return (
    <div className='section'>
      {/* <Modal visible={visible} setVisible={setVisible} /> */}
      <div className='container'>
        <div className='row'>
          <div className='col-md-3 d-flex flex-column align-items-start'>
            <img src={logo} className='img-fluid' alt='img' />
            <p className='text-xs text-dark'>
              TheNorthCop | 2022 All rights Reserved
            </p>
          </div>

          <div className='col-md-3 d-flex flex-column align-items-start'>
            <p className='text-sm bold text-dark'>Page Links</p>
            <a href='#about' className='text-xs text-dark'>
              About
            </a>
            <a href='#features' className='text-xs text-dark'>
              Features
            </a>
            <a href='#community' className='text-xs text-dark'>
              Community
            </a>
            <a href='#download' className='text-xs text-dark'>
              Download
            </a>
            <a href='#faq' className='text-xs text-dark'>
              FAQ's
            </a>
          </div>

          <div className='col-md-3 d-flex flex-column align-items-start'>
            <p className='text-sm bold text-dark'>Socials</p>
            <a
              href='https://twitter.com/TheNorthCop'
              className='text-xs text-dark'
            >
              Twitter
            </a>

            <a
              href='https://instagram.com/thenorthcop'
              className='text-xs text-dark'
            >
              Instagram
            </a>
          </div>

          <div className='col-md-3 d-flex flex-column align-items-start'>
            <p className='text-sm bold text-dark'>Join Us</p>
            <button
              onClick={() => {
                // open tab to dashboard purchase page
                window.open(
                  'https://dashboard.thenorthcop.com/?password=joinnow',
                  '_blank'
                );
                // window.scrollTo(0, 0);
                // setVisible(!visible);
              }}
              style={{
                cursor: 'pointer !important',
                border: 'none',
                backgroundColor: 'white',
                paddingLeft: '0px',
              }}
              className='text-xs text-dark '
            >
              Join Now
            </button>
            <a href='#download' className='text-xs text-dark'>
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
