import React from 'react';
import FeatureOne from './imgs/feature-1.png';
import FeatureTwo from './imgs/feature-2.png';
import FeatureThree from './imgs/feature-3.png';
import FeatureFour from './imgs/feature-4.png';
import FeatureImg from './imgs/feature-img.png';
import 'aos/dist/aos.css';

export default function Features() {
  return (
    <div id='features' className='container'>
      <div className='section'>
        <h6 className='text-lg text-dark bold text-center mb-5'>
          How we can help you on releases
        </h6>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row align-items-baseline mb-5 '>
              <div
                className='col-md-6 d-flex flex-column align-items-start center-mobile'
                data-aos='fade-up'
                data-aos-duration='750'
              >
                <img className='mb-1' src={FeatureOne} alt='img' />
                <p className='text-sm text-dark bold mb-1'>
                  High speed monitors
                </p>
                <p className='text-xs text-dark '>
                  Hundreds of custom monitoring solutions to bring you the
                  quickest restock and drop notifications so you don't miss out
                  on profits.
                </p>
              </div>
              <div
                className='col-md-6 d-flex flex-column align-items-start center-mobile'
                data-aos='fade-up'
                data-aos-duration='1000'
              >
                <img className='mb-1' src={FeatureTwo} alt='img' />
                <p className='text-sm text-dark bold mb-1'>
                  Release Information
                </p>
                <p className='text-xs text-dark '>
                  Access industry leading information for profitable releases
                  and restocks. Know what, where, when and how to Join Now your
                  favorite items.
                </p>
              </div>
            </div>
            <div className='row align-items-baseline '>
              <div
                className='col-md-6 d-flex flex-column align-items-start center-mobile'
                data-aos='fade-up'
                data-aos-duration='1500'
              >
                <img className='mb-1' src={FeatureThree} alt='img' />
                <p className='text-sm text-dark bold mb-1'>1 on 1 support</p>
                <p className='text-xs text-dark '>
                  Get access to industry professionals to take you to the next
                  level. Schedule 1 on 1 tutoring sessions, or open up a support
                  ticket for 24/7 support.
                </p>
              </div>
              <div
                className='col-md-6 d-flex flex-column align-items-start center-mobile'
                data-aos='fade-up'
                data-aos-duration='1750'
              >
                <img className='mb-1' src={FeatureFour} alt='img' />
                <p className='text-sm text-dark bold mb-1'>Bespoke Tools</p>
                <p className='text-xs text-dark '>
                  Included with TheNorthCop, get access to a range of tools to
                  bring you the best success. From our Deskt op Autofill to our
                  Mobile app, you will never be out of the loop.
                </p>
              </div>
            </div>
          </div>
          <div
            data-aos='zoom-out'
            data-aos-duration='1500'
            className='col-md-6'
          >
            <img className='img-fluid' src={FeatureImg} alt='img' />
          </div>
        </div>
      </div>
    </div>
  );
}
