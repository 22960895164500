import React from 'react';
import 'aos/dist/aos.css';

export default function FAQ() {
  return (
    <div id='faq' className='section'>
      <div className='container'>
        <h6 className='text-lg text-dark text-center bold mb-5'>
          Frequently Asked Questions
        </h6>
        <p className='text-xs opaque text-center mb-5'>
          Have a question? Find our most commonly asked questions below.
        </p>
        <div className='faq'>
          <div className='header-container'>
            <div className='accordion' id='accordionExample'>
              <div
                className='accordion-item rounded mb-5'
                data-aos='fade-down'
                data-aos-duration='250'
              >
                <h2 className='accordion-header' id='headingOne'>
                  <button
                    className='accordion-button text-md rounded text-dark bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseOne'
                    aria-expanded='true'
                    aria-controls='collapseOne'
                  >
                    I’m New To This, Will TheNorthCop Be Useful?
                  </button>
                </h2>
                <div
                  id='collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='headingOne'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body text-dark'>
                    Of course! TheNorthCop helps users of all levels and
                    experience secure the items they want. If you are new, we
                    will guide you through all the necessary steps to ensure
                    even you are able to Join Now items. TheNorthCop provides
                    24/7 one on one support to ensure all your questions are
                    answered at any time of the day. New users typically get the
                    hang of things and profit within the first few days of
                    joining.
                  </div>
                </div>
              </div>

              <div
                className='accordion-item rounded mb-5'
                data-aos='fade-down'
                data-aos-duration='500'
              >
                <h2 className='accordion-header' id='headingTwo'>
                  <button
                    className='accordion-button text-md rounded text-dark bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseTwo'
                    aria-expanded='false'
                    aria-controls='collapseTwo'
                  >
                    How Do TheNorthCop Memberships Work?
                  </button>
                </h2>
                <div
                  id='collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingTwo'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body text-dark'>
                    TheNorthCop memberships are based on a monthly subscription
                    system. Once you have been accepted from our membership
                    waitlist you will receive additional information to your
                    email on how to join our discord server. You are able to
                    manage your membership directly on our website by logging in
                    at the top, once logged in you can change/update payment
                    information, unbind your discord or cancel your membership.
                  </div>
                </div>
              </div>

              <div
                className='accordion-item rounded mb-5'
                data-aos='fade-down'
                data-aos-duration='750'
              >
                <h2 className='accordion-header' id='headingThree'>
                  <button
                    className='accordion-button text-md rounded text-dark bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseThree'
                    aria-expanded='false'
                    aria-controls='collapseThree'
                  >
                    What Would I Gain From TheNorthCop?
                  </button>
                </h2>
                <div
                  id='collapseThree'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingThree'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body text-dark'>
                    As a member you will gain exclusive access to an ever
                    expanding list of features, release guides and more. You can
                    utilize these features to bring you additional profits and
                    increase your reselling success.
                  </div>
                </div>
              </div>

              <div
                className='accordion-item rounded mb-5'
                data-aos='fade-down'
                data-aos-duration='1000'
              >
                <h2 className='accordion-header' id='headingFour'>
                  <button
                    className='accordion-button text-md rounded text-dark bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseFour'
                    aria-expanded='false'
                    aria-controls='collapseFour'
                  >
                    Will I Still Be Able To Use TheNorthCop If I Don't Own A
                    Bot?
                  </button>
                </h2>
                <div
                  id='collapseFour'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingFour'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body text-dark'>
                    Yes! Regardless of having access to a bot or not,
                    TheNorthCop has monitors, autofillers and more to keep up
                    with the competition. Eventually you may want to look into
                    purchasing a bot, and we'll be right there to help you
                    through every step of the way.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
