import React, { Component } from "react";
import CountUp from "react-countup";

export default class Analytics extends Component {
  render() {
    return (
      <div id="analytics" className="section red-bg">
        <div className="container d-flex flex-column align-items-center">
          <h6 className="text-md bold text-white text-center mb-4">
            Helping members acquire profitable items since 2018
          </h6>
          <p className="text-xs text-white text-center">
            Acquire profitable items utilizing our tools, software, support &
            more. We sift through the dirt to bring you the gold.{" "}
          </p>

          <div className="row mt-5 w-100 justify-content-around">
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
              <CountUp
                className="text-xl bold text-white"
                start={0}
                end={100}
                duration={2.75}
                separator=","
                decimal=","
                suffix="M+"
              />
              <p className="text-xs text-white">in profits secured</p>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
              <CountUp
                className="text-xl bold text-white"
                start={0}
                end={1500}
                duration={2.75}
                separator=","
                decimal=","
                suffix="+"
              />
              <p className="text-xs text-white">Satisfied Members</p>
            </div>
          </div>
          <a href="#features" className="btn-dark text-sm round mt-5">
            Explore more below
          </a>
        </div>
      </div>
    );
  }
}
