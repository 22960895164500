import React from 'react';
import TestimonialImg from './imgs/testimonials.png';
import 'aos/dist/aos.css';

export default function Testimonials() {
  return (
    <div id='testimonials' className='section'>
      <div className='container'>
        <h6 className='text-lg text-dark bold mb-4 text-center'>
          Read the reviews and see the success
        </h6>
        <p className='text-xs text-dark mb-5 text-center '>
          Our members are the heartbeat of TheNorthCop. Hear what they have to
          say
        </p>
        <img
          data-aos='zoom-out'
          data-aos-duration='500'
          className='img-fluid'
          src={TestimonialImg}
          alt='img'
        />
      </div>
    </div>
  );
}
