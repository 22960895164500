import React from 'react';
import 'aos/dist/aos.css';
// import Modal from './Modal';
import { useState } from 'react';

export default function Pricing() {
  const [visible, setVisible] = useState(false);

  return (
    <div id='pricing' className='section red-bg'>
      {/* <Modal visible={visible} setVisible={setVisible} /> */}
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-md-6 d-flex flex-column align-items-start center-mobile'>
            <h6 className='text-md text-white bold mb-5 center-mobile'>
              Simple pricing
            </h6>
            <h6 className='text-sm text-white mb-5 center-mobile '>
              Cancel Anytime.
            </h6>
            <h6 className='text-xs text-white center-mobile mobile-mb '>
              Joining our community is easy, sign up for the membership waitlist
              to be notified once it's your turn and you will recieve more
              information on how to join our discord server once you've been
              accepted. Have questions? See our frequently asked questions
              below.
            </h6>
          </div>
          <div className='col-md-4' data-aos='zoom-in' data-aos-duration='500'>
            <div className='pricing-card'>
              <h6 className='text-sm text-dark text-center bold mb-5'>
                TheNorthCop Monthly
              </h6>
              <h6 className='text-md text-dark text-center bold'>$50 CAD/m</h6>
              <button
                onClick={() => {
                  // open tab to dashboard purchase page
                  window.open(
                    'https://dashboard.thenorthcop.com/?password=joinnow',
                    '_blank'
                  );
                  // window.scrollTo(0, 0);
                  // setVisible(!visible);
                }}
                style={{
                  cursor: 'pointer !important',
                  border: 'none',
                }}
                className='btn-red text-sm round mt-5 '
              >
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
