import React, { Component } from 'react';
import Header from './Header';
import Analytics from './Analytics';
import Features from './Features';
import Community from './Community';
import Download from './Download';
import Testimonials from './Testimonials';
import Pricing from './Pricing';
import FAQ from './FAQ';
import Footer from './Footer';
// import Dashboard from "./Dashboard";

export default class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <Analytics />
        <Features />
        <Community />
        <Download />
        <Testimonials />
        <Pricing />
        <FAQ />
        <Footer />
      </div>
    );
  }
}
