import React from 'react';
import CommunityImg from './imgs/Community-img.png';
import Users from './imgs/users.png';
import Sell from './imgs/sell.png';
import Build from './imgs/build.png';
// import Modal from './Modal';
import { useState } from 'react';
import 'aos/dist/aos.css';

export default function Community() {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {/* <Modal visible={visible} setVisible={setVisible} /> */}

      <div id='community' className='container'>
        <div className='section'>
          <div className='row'>
            <div className='col-md-6'>
              <img className='img-fluid' src={CommunityImg} alt='img' />
            </div>
            <div className='col-md-6 d-flex flex-column align-items-start center-mobile'>
              <p className='text-md text-dark text-left mb-3 bold center-mobile'>
                Sync with our community
              </p>
              <p className='text-xs opaque text-left center-mobile'>
                It's important to build your network. Surround yourself with
                like minded individuals at all times and create long-lasting
                friendships.
              </p>

              <div
                className='d-flex flex-row align-items-center mb-4 mobile-mx-auto'
                data-aos='fade-up'
                data-aos-duration='500'
              >
                <img className='me-3' src={Users} alt='img' />
                <p className='text-sm bold mb-0'>
                  Meet like minded individuals
                </p>
              </div>

              <div
                className='d-flex flex-row align-items-center mb-4 mobile-mx-auto'
                data-aos='fade-up'
                data-aos-duration='750'
              >
                <img className='me-3' src={Sell} alt='img' />
                <p className='text-sm bold mb-0'>Connect, buy, sell & trade</p>
              </div>

              <div
                className='d-flex flex-row align-items-center mobile-mx-auto'
                data-aos='fade-up'
                data-aos-duration='1000'
              >
                <img className='me-3' src={Build} alt='img' />
                <p className='text-sm bold mb-0'>Build together</p>
              </div>
              <button
                onClick={() => {
                  // open tab to dashboard purchase page
                  window.open(
                    'https://dashboard.thenorthcop.com/?password=joinnow',
                    '_blank'
                  );
                }}
                style={{
                  cursor: 'pointer !important',
                  border: 'none',
                }}
                className='btn-red text-sm round mt-5 mobile-mx-auto  '
              >
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
