import React from 'react';
import Phone from './imgs/app.png';
import 'aos/dist/aos.css';

export default function Download() {
  return (
    <div id='download' className='section red-bg download'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h6 className='text-lg text-white bold mb-4'>
              Download our application
            </h6>
            <p
              className='text-xs text-white text-left mb-5'
              data-aos='fade-down'
              data-aos-duration='250'
            >
              Our members only mobile app brings you additional tools to keep
              you in the loop and take home the profits. Featuring a quick
              autofiller for your favorite sites, inventory tracker, important
              group notifications and more, you will be the first to take
              advantage when items restock.
            </p>
            <div className='d-flex flex-row align-items-center'>
              <a
                href='https://apps.apple.com/app/id1591470203'
                className='btn-outline me-3 round text-sm'
                data-aos='fade-right'
                data-aos-duration='500'
              >
                For iOS
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.axsys.resellify'
                className='btn-outline round text-sm'
                data-aos='fade-right'
                data-aos-duration='750'
              >
                For Android
              </a>
            </div>
          </div>
          <div className='col-md-6'>
            <img className='phone' src={Phone} alt='img' />
          </div>
        </div>
      </div>
    </div>
  );
}
